import { useState, createContext, useContext, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { authService } from "@services"; // El servicio ya maneja errores

const UserContext = createContext(undefined);

export const UserProvider = ({ children }) => {
  const [dataUser, setDataUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // Verificar la autenticación al cargar la aplicación
  useEffect(() => {
    const checkAuthentication = async () => {
      setLoading(true); // Iniciar el estado de carga

      try {
        const token = localStorage.getItem("token");
        const sessionToken = localStorage.getItem("sessionToken");

        // Verificar si hay un token en localStorage
        if (!token) {
          throw new Error("Ups parece que no tienes una sesión activa");
        }

        // Verificar si hay un token de sesión en localStorage
        if (!sessionToken) {
          throw new Error("Ups parece que no tienes una sesión activa");
        }

        // Verificar el tiempo de la session
        let currentTime = new Date().getTime();
        let sessionTime = new Date(sessionToken).getTime();
        let diff = currentTime - sessionTime;
        let diffMinutes = Math.floor(diff / 60000);

        if (diffMinutes > 30) {
          throw new Error("Ups parece que no tienes una sesión activa");
        }

        const { code: verifyCode, error: verifyError, response: verifyResponse } = await authService.verifyToken();

        // Verificar si hay errores en la autenticación
        if (verifyCode === "ERROR") {
          throw new Error(verifyError);
        }

        const scope = Sentry.getCurrentScope();
        scope.setUser({
          id: verifyResponse.id,
          email: verifyResponse.email,
        });

        // Actualizamos el estado si el usuario es válido
        setDataUser(verifyResponse);
        setIsAuthenticated(true);
      } catch (error) {
        setIsAuthenticated(false);
        setDataUser(null);
      } finally {
        setLoading(false); // Finalizar el estado de carga
      }
    };

    checkAuthentication();
  }, []);

  // Método para iniciar sesión
  const handleLogin = async ({ username, password }) => {
    setIsAuthenticated(false); // Establecemos el estado como no autenticado al iniciar el proceso

    try {
      const { code: authCode, error: authError, response: auth } = await authService.login({
        email: username,
        password: password,
      });

      // Verificar si hay errores en la autenticación
      if (authCode === "ERROR") {
        throw new Error(authError);
      }

      // Guardamos los tokens en localStorage
      localStorage.setItem("token", auth.token);
      localStorage.setItem("sessionToken", auth.session_token);

      const { code: verifyCode, error: verifyError, response: verifyResponse } = await authService.verifyToken();

      // Verificar si hay errores en la autenticación
      if (verifyCode === "ERROR") {
        throw new Error(verifyError);
      }

      console.log("verifyResponse", verifyResponse);

      /// Usually, you don't want to write on the current scope, so use with care!
      const scope = Sentry.getCurrentScope();
      scope.setUser({
        id: verifyResponse.id,
        email: verifyResponse.email,
      });

      setDataUser(verifyResponse);
      setIsAuthenticated(true);
      return { success: true }; // Retorna
    } catch (error) {
      return { success: false, message: error.message }; // Retorna algo siempre
    }
  };

  // Método para cerrar sesión
  const handleLogout = async () => {
    await authService.logout();
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("token");
    setDataUser(null);
    setIsAuthenticated(false);
    Sentry.setUser(null); // Limpiar el usuario de Sentry
  };

  return (
    <UserContext.Provider
      value={{
        dataUser,
        isAuthenticated,
        loading,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserProvider = () => useContext(UserContext);
