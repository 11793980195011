import axios from "axios";

class httpService {
  constructor({ baseUrl, useToken = false, defaultHeaders = {} }) {
    this.client = axios.create({
      baseURL: baseUrl,
      headers: defaultHeaders,
    });

    // Interceptor para añadir token si es necesario
    if (useToken) {
      this.client.interceptors.request.use(
        (config) => {
          const token = localStorage.getItem("token"); // Ajustar la obtención del token según tu contexto
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => Promise.reject(error)
      );
    }
  }

  async request(method, url, data = null) {
    try {
      const response = await this.client.request({ method, url, data });
      return response.data;
    } catch (error) {
      // Manejo de errores centralizado
      const errorMessage = this.handleError(error);
      throw new Error(errorMessage);
    }
  }

  get(url, params) {
    return this.request("get", url, { params });
  }

  post(url, data) {
    return this.request("post", url, data);
  }

  put(url, data) {
    return this.request("put", url, data);
  }

  delete(url, data) {
    return this.request("delete", url, data);
  }

  handleError(error) {
      // Capturamos todos los errores de red
      if (error.message === "Network Error") {
        console.error("Error de conexión a internet:", error.message);
        return "Error de conexión a internet, por favor intente más tarde.";
      }

      // Capturamos errores de timeout
      if (error.code === "ECONNABORTED") {
        console.error("Error de conexión a internet:", error.message);
        return "La solicitud ha tardado mucho tiempo en procesarse.";
      }

      // Capturamos cualquier otro error de red
      if (error.message === "Request failed with status code 404") {
        console.error("Error de conexión a internet:", error.message);
        return "No se encontró la página solicitada.";
      }

      if (error.message === "Request failed with status code 401") {
        console.error("Error de conexión a internet:", error.message);
        return "No autorizado, inicie sesión para continuar.";
      }

      if (error.message === "Request failed with status code 500") {
        console.error("Error de conexión a internet:", error.message);
        return "Error en el servidor, por favor intente más tarde.";
      }

      if (error.message === "Request failed with status code 503") {
        console.error("Error de conexión a internet:", error.message);
        return "Servicio no disponible, por favor intente más tarde.";
      }

      return "Ocurrió un error al procesar la solicitud.";
  }

}

export default httpService;
