import {
	useQuery,
	useMutation,
	useQueryClient,
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query';

// GA
import ReactGA from "react-ga4";

// Sentry
import * as Sentry from "@sentry/react";

// utils
import { lazy, Suspense } from "react";

// styles
import ThemeStyles from "@styles/theme";
import "./style.scss";

// libs styles
import "react-toastify/dist/ReactToastify.min.css";
import "react-grid-layout/css/styles.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

// fonts
import "@fonts/icomoon/icomoon.woff";

// contexts
import { SidebarProvider } from "@contexts/sidebarContext";
import { ThemeProvider } from "styled-components";
import { UserProvider } from "@contexts/userContext";

// hooks
import { useThemeProvider } from "@contexts/themeContext";
import { useEffect, useRef } from "react";
import { useWindowSize } from "react-use";
import useAuthRoute from "@hooks/useAuthRoute";

// utils
import { StyleSheetManager } from "styled-components";
import {
	ThemeProvider as MuiThemeProvider,
	createTheme,
} from "@mui/material/styles";
import { preventDefault } from "@utils/helpers";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// components
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoadingScreen from "@components/LoadingScreen";
import Sidebar from "@layout/Sidebar";
import BottomNav from "@layout/BottomNav";
import Navbar from "@layout/Navbar";
// import ShoppingCart from '@widgets/ShoppingCart';
import ScrollToTop from "@components/ScrollToTop";

// pages
// const ClubSummary = lazy(() => import('@pages/ClubSummary'));
const ClubSummaryAlas = lazy(() => import("@pages/ClubSummaryAlas"));
const GameSummary = lazy(() => import("@pages/GameSummary"));
const Championships = lazy(() => import("@pages/Championships"));
// const LeagueOverview = lazy(() => import('@pages/LeagueOverview'));
// const FansCommunity = lazy(() => import('@pages/FansCommunity'));
const Statistics = lazy(() => import("@pages/Statistics"));
const PageNotFound = lazy(() => import("@pages/PageNotFound"));
const MatchSummary = lazy(() => import("@pages/MatchSummary"));
const MatchOverview = lazy(() => import("@pages/MatchOverview"));
const PlayerProfile = lazy(() => import("@pages/PlayerProfile"));
const Schedule = lazy(() => import("@pages/Schedule"));
const Tickets = lazy(() => import("@pages/Tickets"));
const FootballStore = lazy(() => import("@pages/FootballStore"));
const BrandStore = lazy(() => import("@pages/BrandStore"));
const Product = lazy(() => import("@pages/Product"));
const Login = lazy(() => import("@pages/Login"));
const SignUp = lazy(() => import("@pages/SignUp"));
const Settings = lazy(() => import("@pages/Settings"));

// Alas pages
// const Eventsview = lazy(() => import('@pages/Events'));
// const EventDetailview = lazy(() => import('@pages/EventDetail'));
// const EventDetailRequirementsview = lazy(() => import('@pages/EventDetailRequirements'));
const TeamsView = lazy(() => import("@pages/Teams"));
const TeamDetailView = lazy(() => import("@pages/TeamsDetails"));

// Create a client
const queryClient = new QueryClient();

// Sentry
const dsn = process.env.REACT_APP_SENTRY_DSN;

if (dsn) {
	Sentry.init({
		dsn,
		initialScope: {
			tags: { "alas-tag": "rocket-camp" },
			user: null,
		},
		integrations: [
			Sentry.browserTracingIntegration(),
			// Sentry.replayIntegration(),
		],
		// Tracing
		tracesSampleRate: 1.0, // Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", /^https:\/\/rocketcamp.alascorporation\.com/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

const App = () => {
	const appRef = useRef(null);
	const { theme, direction } = useThemeProvider();
	const { width } = useWindowSize();
	const isAuthRoute = useAuthRoute();

	// Google Analytics init
	const gaKey = process.env.REACT_APP_PUBLIC_GA;
	gaKey && ReactGA.initialize(gaKey);

	// auto RTL support for Material-UI components and styled-components
	const plugins = direction === "rtl" ? [rtlPlugin] : [];

	const muiTheme = createTheme({
		direction: direction,
	});

	const cacheRtl = createCache({
		key: "css-rtl",
		stylisPlugins: plugins,
	});

	// Componente para redirecionar rutas
	const RouteRedirector = ({ to }) => {
		return window.location.replace(to);
	};

	useEffect(() => {
		// scroll to top on route change
		appRef.current && appRef.current.scrollTo(0, 0);

		preventDefault();
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<CacheProvider value={cacheRtl}>
				<MuiThemeProvider theme={muiTheme}>
					<SidebarProvider>
						<ThemeProvider theme={{ theme: theme }}>
							<UserProvider>
								<ThemeStyles />
								<ToastContainer
									theme={theme}
									autoClose={2500}
									position={direction === "ltr" ? "top-right" : "top-left"}
								/>
								<StyleSheetManager stylisPlugins={plugins}>
									<div
										className={`app ${isAuthRoute ? "fluid" : ""}`}
										ref={appRef}
									>
										<ScrollToTop />
										<div className="app_container">
											<div className="app_container-content d-flex flex-column flex-1">
												<Suspense fallback={<LoadingScreen />}>
													<Routes>
														<Route
															path="/club-summary"
															element={<ClubSummaryAlas />}
														/>
														<Route
															path="/game-summary"
															element={<GameSummary />}
														/>
														<Route
															path="/championships"
															element={<Championships />}
														/>
														<Route path="/statistics" element={<Statistics />} />
														<Route
															path="/match-summary"
															element={<MatchSummary />}
														/>
														<Route
															path="/match-overview"
															element={<MatchOverview />}
														/>
														<Route
															path="/player-profile"
															element={<PlayerProfile />}
														/>
														<Route path="/schedule" element={<Schedule />} />
														<Route path="/tickets" element={<Tickets />} />
														<Route
															path="/football-store"
															element={<FootballStore />}
														/>
														<Route path="/brand-store" element={<BrandStore />} />
														<Route path="/product" element={<Product />} />
														{/* Rutas completadas */}
														<Route path="/login" element={<Login />} />
														<Route path="/sign-up" element={<SignUp />} />
														<Route path="/settings" element={<Settings />} />
														<Route path="*" element={<PageNotFound />} />
														<Route
															path="/"
															element={
																<RouteRedirector to="/concurso-colombiano-de-coheteria-deportiva/2024" />
															}
														/>
														<Route path="/teams" element={<TeamsView />} />
														<Route
															path="/teams/:teamId"
															element={<TeamDetailView />}
														/>
														<Route
															path="/concurso-colombiano-de-coheteria-deportiva/2024"
															element={<Championships />}
														/>
														<Route
															path="/concurso-colombiano-de-coheteria-deportiva/2024/team/:teamId"
															element={<ClubSummaryAlas />}
														/>
													</Routes>
												</Suspense>
											</div>
										</div>
									</div>
								</StyleSheetManager>
							</UserProvider>
						</ThemeProvider>
					</SidebarProvider>
				</MuiThemeProvider>
			</CacheProvider>
		</QueryClientProvider>
	);
};

export default App;
