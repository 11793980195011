// eventsService.js
import { apiPrivate, apiPublic, handleErrorResponse, handleUnexpectedResponse } from "./apiClient"; // Instancias compartidas

const eventsService = {
  get: async () => {
    try {
      const response = await apiPrivate.get("events");
      return response;
    } catch (error) {
      return handleErrorResponse(error);
    }
  },

  detail: async (eventId) => {
    try {
      const response = await apiPublic.get(`events/${eventId}`);
      return response;
    } catch (error) {
      return handleErrorResponse(error);
    }
  },

  myTeamn: async (eventId) => {
    try {
      const response = await apiPrivate.get(`events/${eventId}/me/team`);
      return response;
    } catch (error) {
      return handleErrorResponse(error);
    }
  },

  calendar: async (eventId) => {
    try {
      const response = await apiPrivate.get(`events/${eventId}/calendar`);
      return response;
    } catch (error) {
      return handleErrorResponse(error);
    }
  },

  teams: async (eventId) => {
    try {
      const response = await apiPrivate.get(`events/${eventId}/teams`);
      return response;
    } catch (error) {
      return handleErrorResponse(error);
    }
  },

  team: async (eventId, teamId) => {
    try {
      const response = await apiPrivate.get(
        `events/${eventId}/teams/${teamId}`
      );
      return response;
    } catch (error) {
      return handleErrorResponse(error);
    }
  },
};

export default eventsService;
