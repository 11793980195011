// httpClients.js
import httpService from "./httpService"; // Cambia la ruta según la estructura de tu proyecto

// Obtener la variable de entorno
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Instancias de httpService configuradas
export const apiPrivate = new httpService({
  useToken: true,
  baseUrl: API_BASE_URL,
  defaultHeaders: {
    "X-Cookie": "true",
  },
});

export const apiPublic = new httpService({
  useToken: false,
  baseUrl: API_BASE_URL,
  defaultHeaders: {
    "X-Cookie": "true",
  },
});

export const handleUnexpectedResponse = () => {
  return {
    code: "ERROR",
    error: "Unexpected response",
    response: null,
  };
}

export const handleErrorResponse = (error) => {
  return {
    code: "ERROR",
    error: error.message,
    response: null,
  };
};