import { toast } from "react-toastify";
import { apiPrivate, apiPublic, handleErrorResponse, handleUnexpectedResponse } from "./apiClient"; // Instancias compartidas

const authService = {
  // Verificación del token actual
  verifyToken: async () => {
    try {
      const { response } = await apiPrivate.get("me");
      return {
        code: "OK",
        error: null,
        response
      };
    } catch (error) {
      return handleErrorResponse(error);
    }
  },

  // Inicio de sesión
  login: async ({ email, password }) => {
    try {
      const { response } = await apiPublic.post("login", { email, password });
      return {
        code: "OK",
        error: null,
        response
      };
    } catch (error) {
      return handleErrorResponse(error);
    }
  },

  // Cierre de sesión
  logout: async () => {
    try {
      const response = await apiPrivate.get("close");

      if (response.status === 200) {
        return {
          code: "OK",
          error: null,
          response: [],
        };
      } else {
        return handleUnexpectedResponse();
      }
    } catch (error) {
      return handleErrorResponse(error);
    }
  },

  // Registro de usuario
  register: async (params) => {
    try {
      const { birth, fullName, email, password, passwordConfirm, userName, typeAccount, country, city } = params;

      // Formateamos la fecha de nacimiento (dd/mm/yyyy a yyyy-mm-dd)
      const formattedBirth = formatBirthDate(birth);

      const response = await apiPublic.post("register", {
        name: fullName,
        email,
        password,
        password_confirmation: passwordConfirm,
        user_name: userName,
        birthdate: formattedBirth,
        type_account: typeAccount.value,
        country: country.value,
        city: city.value,
      });

      return {
        code: "OK",
        error: null,
        response: response.data,
      };
    } catch (error) {
      return handleErrorResponse(error);
    }
  },
};

// Formatear fecha de nacimiento
const formatBirthDate = (birth) => {
  const [day, month, year] = birth.split("/");
  return `${year}-${month}-${day}`;
};

export default authService;
